import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Image from "gatsby-image"

require(`katex/dist/katex.min.css`)
require(`./bibtex.css`)

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const images = {};
    for (let image in this.props.data.images.edges) {
      images[this.props.data.images.edges[image].node.relativePath] = this.props.data.images.edges[image].node
    }

    let i = 0;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <h1>{post.frontmatter.title}</h1>
        <span style={{ fontSize: '1.2em' }}>{post.frontmatter.description}</span>

        <div style={{ width: "98.5vw", marginLeft: '-50vw', marginRight: '-50vw', position: 'relative', right: '50%', left: '50%', background: '#eee', marginTop: '30px', marginBottom: '30px', overflow: 'none' }}>
          <Image style={{ maxHeight: '540px' }} fluid={images[post.frontmatter.featured_image.relativePath].childImageSharp.fluid} />
        </div>


        <MDXRenderer imgs={post.imgs}>{post.body}</MDXRenderer>

        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />
      </Layout >
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featured_image {publicURL, relativePath}
        description
      }
    }
    images: allFile(filter: {
      absolutePath: { regex: "/content/projects/(.*)/(.*).[png|jpg]/" }
    }) {
      edges {
        node {
          relativePath
          childImageSharp  {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
